<template>
	<ion-page>
		<A-header title="Login" />
		
		<ion-content :fullscreen="true">
			<A-collapse-header title="Login" />

			<ion-item>
				<ion-label position="floating">Email</ion-label>
				<ion-input :autofocus="true" :clearInput="true" inputmode="email" v-model="form.login" enterkeyhint="next" @keydown.enter="$refs.password.$el.setFocus()"></ion-input>
			</ion-item>

			<ion-item>
				<ion-label position="floating">Password</ion-label>
				<ion-input type="password" :clearInput="true" :clearOnEdit="true" enterkeyhint="done" :min="8" v-model="form.password" @keydown.enter="submit()" ref="password"></ion-input>
			</ion-item>

			<p>Or you can <span @click="$router.replace({name: 'Register'})">create new account</span></p>
		
		</ion-content>

		<ion-footer>
			<ion-toolbar>
				<ion-button expand="block" color="primary" fill="solid" enterkeyhint="done" @click="submit()">Login</ion-button>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<script>
import AHeader 				from '@/plugins/appshoppinglist/layout/a-header.vue'
import ACollapseHeader 		from '@/plugins/appshoppinglist/layout/a-collapse-header.vue'
import { toastController } 	from '@ionic/vue'

export default {
	components: {
		AHeader,
		ACollapseHeader
	},

	data() {
		return {
			form: {}
		}
	},

	methods: {
		async submit() {
			this.$wLoader.startLoading('Logging in...')

			try {
				await this.$store.dispatch('wAuth/login', this.form)
				this.$router.replace({name: 'Lists'})
			} catch (error) {
				const toast = await toastController.create({
					message: error.data.error,
					duration: 2000,
					color: 'danger',
					position: 'bottom'
				})

				toast.present()
			}

			this.$wLoader.clearLoading()
		}
	}
}
</script>

<style lang="sass" scoped>
ion-item
	--padding-start: 20px
	--padding-end: 20px
	--border-color: var(--ion-color-primary)

p
	padding-left: 20px
	margin-top: 30px

	span
		color: var(--ion-color-primary)

ion-footer
	ion-toolbar
		--border-style: none !important
		--padding-bottom: 10px
</style>